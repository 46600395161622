
/* .app {
    font-family: Nunito Sans, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    width: 100%;
  } */
  
  .sidebar {
    width: 14%; /* 1/5 of the page width */
    height: 100vh;
    padding: 18px;
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Style for the main content area */
  .main-content {
  margin-right: 2.5%;
  margin-top: 3.4%;
  margin-left: 16.3%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }


  
  .main-content h1 {
    font-size: 24px;
    text-align: left;
    color: #4a4af4;
  }
  
  .main-content .import-text {
    color: #4a4af4;
    font-size: 24px;
  }
  
  .main-content .message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: #f3f4f6;
  }

  .main-content h2 {
    font-size: 20px;
    font-weight: 400px;
    text-align: left;
    color: #6366f1;
  }

  .main-content .label {
    font-size: 16px;
    font-weight: 400px;
    text-align: left;
    color: #000000;
  }

  .main-content .value {
    font-size: 14px;
    color: #4f4f4f;
  }

  .main-content .campaign-select {
    text-align: left;
  }

  .lead-management .h1 {

    text-align: left;
    
  }


