

.campaign-analytics {
  padding: 20px;
  margin-right: 3%;
  margin-left: 17.2%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaign-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eceef4;
  padding: 10px 20px;
  border-radius: 8px;
}

.campaign-header h1 {
  
  font-size: 24px;
  text-align: left;
  color: #344ce4;
}

.campaign-sel {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 15px;
}

.campaign-sel label {
  font-weight: bold;
  margin-right: 10px;
}

.campaign-sel select {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.campaign-status {
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 400px;
}

.campaign-status.completed {
  background-color: #eefff0;
  color: rgb(49, 49, 49);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaign-status.in-progress {
  background-color: #239af5;
  color: rgb(255, 255, 255);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaign-status.pending {
  background-color: #fa2a2a;
  color: rgb(255, 255, 255);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaign-info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaign-details h1 {
  font-size: 18px;
  text-align: left;
  margin: 0;
  color: #344ce4;
}

.campaign-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 16px;
  float: right;
  margin-left: 20%; 
}

.campaign-stats div {
  width: 180px;
}

.campaign-stats span {
  font-weight: bold;
  font-size: 15px;
}

.campaign-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.leads{
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 36%;
  margin-bottom: 10px;
  height: 400px;
  font-size: 20px;
}

.campaign-overview {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-left: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 60%;
  margin-bottom: 10px;
  height: 400px;
  font-size: 15px;
}

.leads h1, .campaign-overview h1 {
  font-size: 18px;
  margin-top: 0;
  color: #344ce4;
}

.campaign-overview .large-text {
  height: 75px;
  padding: 15px;
}

table {
  width: 120%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  text-align: left;
  font-size: 1;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.actions {
  display: flex;
  gap: 5px;

}

.action-button {
  background-color: #eceef4;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #d0d4e3;
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input, form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.leads-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.leads-header h3 {
  margin: 0;
}

.add-lead-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px; 
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-lead-btn:hover {
  background-color: #45a049;
}

.view-btn {
  font-size: 15px;
  color: #5D5FEF;
}