.info-action-container {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    background-color: #f9fafb;
    display: flex;
    align-items: flex-start;
    gap: 70px;
    flex-wrap: nowrap;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    min-width: 0;
  }
  
  .created-at {
    flex-shrink: 0;
  }
  
  .file {
    flex-grow: 1;
  }
  
  .campaign {
    flex-shrink: 0;
  }
  
  .generate {
    flex-shrink: 0;
  }
  
  .save {
    flex-shrink: 0;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    white-space: nowrap;
    color: #6b7280;
    font-size: 14px;
  }
  
  .value {
    font-weight: bold;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .upload-status {
    color: #10b981;
  }
  
  /* .campaign-select,
  .generate-btn,
  .save-btn {
    width: 100%;
  } */
  
  .generate-btn, .save-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .generate-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .generate-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .save-btn {
    background-color: #0aae6d;
    color: white;
  }

  .save-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }