
.lead-management {
  padding: 20px;
  margin-right: 3%;
  margin-left: 17%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.header h1 {
  font-size: 24px;
  color: #5D5FEF;
}

.controls {
  display: flex;
  gap: 10px;
}

.sort-btn, .filter-btn {
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #5D5FEF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-btn:hover, .filter-btn:hover {
  background-color: #5D5FEF;
}

.leads-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  
}

.leads-table thead tr {
  background-color: #f9f9f9;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}

.leads-table th, .leads-table td {
  padding: 12px 15px;
  font-size: 14px;
  font-weight: 600;
  border: 0px solid #ddd;
  text-align: left;
}

.leads-table th {
  cursor: pointer;
  user-select: none;
}
  .leads-table th span {
    margin-left: 8px;
  }
  
  .leads-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .leads-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .action-btn {
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    transition: color 0.3s ease;
  }
  
  .action-btn.view {
    color: #28a745;
  }
  
  .action-btn.edit {
    color: #ffc107;
  }
  
  .action-btn.view:hover {
    color: #218838;
  }
  
  .action-btn.edit:hover {
    color: #e0a800;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  
  .pagination-controls {
    display: flex;
    gap: 10px;
  }
  
  .prev-btn, .next-btn {
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .prev-btn:hover, .next-btn:hover {
    background-color: #0056b3;
  }


  .campaign-select {
    margin-bottom: 5px;
    text-align: center;
  }
  
  .campaign-select label {
    font-weight: bold;
    margin-right: 20px;
  }
  
  .campaign-select select {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .add-to-campaign-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 11px;
    margin: 2px 2px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .add-to-campaign-btn:hover {
    background-color: #45a049;
  }
  
  .add-to-campaign-btn:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  
  .add-to-campaign-btn.added {
    background-color: #2196F3;
  }
  
  .add-to-campaign-btn.added:disabled {
    background-color: #2196F3;
    color: white;
    opacity: 0.7;
  }

  .editable-note {
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .editable-note:hover {
    background-color: #f0f0f0;
  }
  
  .editable-note input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
  }
  
  .editable-note input:focus {
    outline: none;
    border-color: #2196F3;
    box-shadow: 0 0 5px rgba(33, 150, 243, 0.3);
  }

  .filter-section {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  .filter-section select,
  .filter-section input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    min-width: 200px;
    height: 40px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s ease;
  }
  
  .filter-section select:focus,
  .filter-section input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
  }
  
  .filter-section input::placeholder {
    color: #999;
  }
  
  /* Optional: Add hover effect */
  .filter-section select:hover,
  .filter-section input:hover {
    border-color: #b3b3b3;
  }