.page-container {
  display: flex;
  background: #FAFBFF;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 40px;
  margin-left: 240px;
}

.page-title {
  color: #6366F1;
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 40px;
}

.toggle-section {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 48px;
}

.billing-toggle {
  display: flex;
  gap: 8px;
}

.toggle-button {
  padding: 10px 24px;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  background: white;
  border: 1px solid #E5E7EB;
  color: #6B7280;
  transition: all 0.2s;
}

.toggle-button.active {
  background: #6366F1;
  color: white;
  border-color: #6366F1;
}

.org-select {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  min-width: 200px;
  font-size: 14px;
  appearance: none;
  background: white url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat right 16px center;
  padding-right: 40px;
  cursor: pointer;
  color: #374151;
}

.pricing-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto 48px;
  padding: 0 64px;
}

.pricing-card {
  background: white;
  border-radius: 24px;
  padding: 40px 32px;
  position: relative;
  border: 1px solid #E5E7EB;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.pricing-card.current {
  background: #F8F9FF;
}

.pricing-card.scale {
  background: #6366F1;
  color: white;
}

.badge {
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 6px 16px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
}

.badge.current {
  background: #EEF2FF;
  color: #6366F1;
}

.badge.popular {
  background: white;
  color: #6366F1;
}


.plan-title {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 4px;
  color: #111827;
}

.scale .plan-title {
  color: white;
}

.plan-subtitle {
  font-size: 14px;
  color: #6B7280;
  margin-bottom: 24px;
}


.scale .plan-subtitle {
  color: rgba(255, 255, 255, 0.8);
}

.price-section {
  text-align: center;
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}


.price-amount {
  display: inline-flex;
  position: relative;
  padding-left: 16px; /* Make room for the currency */
}


.currency {
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 16px;
  font-weight: normal;
  color: #6B7280;
}


.scale .currency {
  color: white;
}

.amount {
  font-size: 48px;
  font-weight: normal;
  line-height: 1;
  color: #111827;
  }

.scale .amount {
  color: white;
  }

.period {
  font-size: 14px;
  color: #6B7280;
  margin-top: 4px;
  }


.scale .period {
  color: rgba(255, 255, 255, 0.8);
}

.features-list {
  margin: 0;
  padding: 0;
  list-style: none;
  flex-grow: 1;
  margin-bottom: 32px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  color: #6B7280;
  font-size: 14px;
}

.scale .feature-item {
  color: rgba(255, 255, 255, 0.9);
}

.checkmark {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  color: #6366F1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.scale .checkmark {
  color: white;
}

.storage-info {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  margin-top: auto;
  border-top: 1px solid #E5E7EB;
  color: #6B7280;
  font-size: 14px;
}


.scale .storage-info {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.8);
}

.action-button {
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background: white;
  color: #6366F1;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #E5E7EB;
  margin-top: 24px;
  transition: all 0.2s;
}

.action-button:hover {
  background: #F9FAFB;
}

.scale .action-button {
  background: white;
  border: none;
}

.scale .action-button:hover {
  background: #F5F7FF;
}

.features-bar {
  display: flex;
  justify-content: center;
  gap: 48px;
}

.features-bar .feature-item {
  color: #6B7280;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.features-bar .checkmark {
  color: #6366F1;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #6B7280;
}

/* Hover states and interactions */
.toggle-button:hover:not(.active) {
  background: #F9FAFB;
}

.org-select:hover {
  border-color: #D1D5DB;
}

.action-button:focus,
.toggle-button:focus,
.org-select:focus {
  outline: none;

}

.subscription-container {
  display: flex;
  gap: 24px;
  padding: 24px;
}

.subscription-content {
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
}

.usage-meter {
  margin: 20px 0;
}

.MuiLinearProgress-root {
  height: 8px;
  border-radius: 4px;
}

.MuiAlert-root {
  margin-bottom: 16px;
}