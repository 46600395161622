.edit-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .edit-popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
  }
  
  .edit-popup-content form {
    display: flex;
    flex-direction: column;
  }
  
  .edit-popup-content label {
    margin-bottom: 10px;
  }
  
  .edit-popup-content input {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
  }
  
  .edit-popup-content button {
    padding: 10px;
    font-size: 16px;
    margin: 5px;
  }
  