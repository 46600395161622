.toggle-container {
    padding: 4px;
    background-color: #000000;
    border-radius: 25px !important;
}
.toggle-button-a {
    border-radius: 25px !important;
    padding: 0px !important;
    text-transform: capitalize !important;
}

.toggle-button-l {
    padding: 5px !important;
    background-color: black;
    text-transform: capitalize !important;
}
.left-sidebar {
    padding: 15px;
}
.create-new-client-button{    
    margin: 20px 0 !important;
}

.start-new-chat-button{
    margin: 20px 0 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-1q01gco-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    margin: 20px 0;
}
h6.MuiTypography-root.MuiTypography-subtitle2.css-nzms8d-MuiTypography-root {
    margin: 10px;
    text-align: justify;
}
.MuiBox-root.css-qiam2h {
    padding: 10px 0;
}
img.left-arrow-img {
    position: fixed;
    z-index: 1000;
    margin-left: 20px;
    margin-bottom: 40px;
}
.MuiBox-root.css-gjglbw {
    padding: 10px;
    border-radius: 25px;
    background-color: #5d5fef38;
}
h6.MuiTypography-root.MuiTypography-h6.prompt-library-title.css-8zgow4-MuiTypography-root {
    font-size: 20px !important;
    font-weight: 700;
}
button.add-prompt-button {
    text-transform: capitalize;
    padding: 5px;
    background-color: #5D5FEF !important;
    color: white !important;
}
.MuiBox-root.css-f524ba {
    padding: 30px;
}
.chat-content {
    background-color: #f8fafc;
}
.MuiBox-root.css-s23m30 {
    padding: 30px;
}
.message-text {
    text-align: justify !important;
}
.input-end-adornment{
        border: solid 1px #5D5FEF;
}
.chat-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.query-box {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.chat-content {
    display: flex;
    flex-direction: column;
  }
  
  .message-bubble {
    max-width: 70%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
  }
  
  .user-message {
    align-self: flex-start I !important; 
    background-color: #e0f7fa;
  }
  
  .bot-message {
    align-self: flex-end !important; 
    background-color: #f1f8e9; 
  }
  
  .message-text {
    color: #333;
  }

  .css-rsks93-MuiPaper-root-MuiDialog-paper {
    max-width: 65% !important;
}
.dialog-textfield::placeholder {
    background-color: black !important;
}
.css-zfx7vw-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: #F5F6FA;
    border-radius: 20px !important;
    border: solid 1px #6366f1a1;
}
button.dialog-add-button {
    text-transform: capitalize;
    background-color: #5D5FEF !important;
    color: white !important;
}
h6.add-your-own-prompt {
    text-align: justify;
}

.user-message {
    place-self: start;
   }
   .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-157078q {
    margin: 20px 0;
   }
   svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-1l3b12y {
    color: white;
   }
   .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-mp9f0v {
   background-color: #5D5FEF;
   color: white;
   padding: 10px;
   border-radius: 10px;
   }

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.css-ghr9bt {
    text-transform: capitalize;
    background-color: #5D5FEF;
    color: white;
    border-radius: 10px;
}

.list-item-icon {
    min-width: 30px!important;
  }

  .MuiTypography-body2.MuiListItemText-secondary {
      font-size: 12px !important;
     text-align: justify;
  }
 
  .MuiListItem-padding.chat-list-item {
     padding: 0px;
     margin-bottom: 0px !important;
  }
 
  h6.MuiTypography-root.MuiTypography-subtitle2.css-141bcmy {
      text-align: justify;
      margin: 5px;
  }

  p.Select.Client {
     font-weight: 600;
      text-align: justify;
      margin-left: 5px;
  }
 
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.css-1oi8ejm {
     margin-top: 10px;
  }
 
  li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.css-foohz8 {
     background-color: white;
      padding-left: 10px;
      margin-bottom: 15px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1kmkvia {
      border: solid 1px #5D5FEF;
  }

  .input-end-adornment {
     border: 0px solid #5D5FEF !important;
  }
 
  h6.MuiTypography-root.MuiTypography-h6.prompt-library-title.css-1rl0qlz {
      font-size: 18px !important;
      font-weight: 600;
      margin-left: 15px;
  }
  hr.MuiDivider-root.MuiDivider-fullWidth.css-3nb6kf {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}
.toggle-container {
    min-height: min-content !important;
}