.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: Nunito Sans;
    width: 250px; /* Full width when open */
    background-color: #ffffff;
    color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
    transition: width 0.3s ease; /* Smooth collapse animation */
  }
  .sidebar.collapsed {
    width: 70px; /* Collapsed width showing only icons */
  }
  .logo-container {
    text-align: left;
    margin-bottom: 0px;
    padding: 10px 0;
  }
  .logo {
    max-width: 50%; /* Adjusted for better fit */
    height: auto;
    transition: max-width 0.3s ease; /* Smooth logo resize */
  }
  .sidebar.collapsed .logo {
    max-width: 100%; /* Logo becomes larger in collapsed state */
  }
  .menu-container {
    flex-grow: 1;
  }
  .sidebar nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .nav-button {
    /*width: 100%;*/
    text-align: left;
    padding: 6px;
    border: none;
    background: none;
    font-family: 'Nunito Sans', sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #232323;
    transition: background-color 0.3s ease, padding 0.3s ease;
  }
  .nav-button i {
    margin-right: 10px;
    font-size: 16px; /* Adjust icon size */
  }
  .sidebar.collapsed .nav-button i {
    margin-right: 0; /* Remove margin when collapsed */
  }
  .nav-button span {
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transition: opacity 0.3s ease;
    margin-left: 10px;
  }
  .sidebar.collapsed .nav-button span {
    opacity: 0;
  }
  .nav-button.active {
    background-color: #5D5FEF;
    color: white;
    border-radius: 5px;
  }
  .nav-button:hover {
    background-color: #5D5FEF;
    color: white;
    border-radius: 5px;
  }
  .menu-divider {
    height: 2px;
    background-color: #808080;
    margin: 15px 0;
  }
  .logout-container {
    margin-top: auto;
  }
  .logout-button {
    /*width: 100%;*/
    padding: 10px;
    background-color: #ffffff;
    color: #E51A1A;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
  }
  .sidebar.collapsed .logout-button {
    justify-content: center; /* Center icon when collapsed */
  }
  .logout-button span {
    margin-right: 8px;
    margin-left: 10px;
    font-size: 14px !important;
  }
  .logout-button:hover {
    background-color: #f7dbd8;
  }
  .sidebar-toggle {
    position: absolute;
    top: 10px;
    left: 220px; /* Adjust based on sidebar width */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: left 0.3s ease;
  }
  .sidebar.collapsed + .sidebar-toggle {
    left: 49px;
  }
  .user-info h6 {
    font-size: 16px !important;
    color: black;
    line-height: 1 !important;
    text-align: justify;
  }
  .user-info span{
    line-height: 1 !important;
  }
.user-info button {
  margin: 5px 0 0px 0;
}
.user-info {
  margin: 10px 0 40px 0;
}
.middle-menu button {
  width: 95%;
  margin-bottom: 5px;
}
hr {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.cl-internal-17dpwu0 {
  height: 100% !important;}

h4.MuiTypography-root.MuiTypography-h4.MuiTypography-gutterBottom.settings-title.css-yvxppd-MuiTypography-root {
    font-size: 32px !important;
    text-align: justify;
}

  button.nav-button span {
    font-size: 14px !important;
}
.basic-button{
  padding: 4px !important;
  text-transform: capitalize;
}
.middle-menu {
  margin-bottom: 40% !important;
}
.cl-userButtonAvatarImage.🔒️.cl-internal-fhootk {
    width: 48px;
}

.logout-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
  }
  .sidebar nav ul {
    margin-left: 10px;
  }
  .user-info {
    margin-left: 10px;
  }
  .logo-container {
    margin-left: 10px;
  }