@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap");

.MuiBox-root.css-0 {
    height: 100% !important;
    width: 100%;
    align-content: center;
    align-self: center;
    background-color: #F5F6FA;
    border-radius: 30px;
}
.MuiBox-root.css-1dofwj8 {
    justify-content: center;
}
h4, h6, p{
    text-align: center;
    font-family: 'Nunito', sans-serif !important;
}
span {
    font-family: 'Nunito', sans-serif !important;
}

p {
    font-size: 16px !important;
}
.analytics-button {
    width: 300px;
    height: 150px;
    font-size: 20px !important;
}
.lead-gen-button {
    width: 300px;
    height: 150px;
    font-size: 20px !important;
}
.MuiBox-root.css-gkqry8 {
    background-color: white;
}
.MuiBox-root.css-d0uhtl {
    margin-left: 0px !important;
}
span.cl-avatarBox.cl-userButtonAvatarBox.🔒️.cl-internal-1j7ahlv {
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
}
h4 {
    font-size: 55px !important;
}
h6 {
    font-size: 16px ! IMPORTANT;
}
.css-1ji0vyp-MuiListItem-root:hover {
    background-color: #5D5FEF !important;
}
ul.MuiList-root.MuiList-padding.css-1u169s7-MuiList-root {
    padding: 10px !important;
}
li.MuiListItem-root {
    border-radius: 10px;
    margin-bottom: 5px;
}
.css-cveggr-MuiListItemIcon-root {
    min-width: 35px !important;}

 h6.MuiTypography-root.MuiTypography-subtitle2.css-sexpzk-MuiTypography-root {
        font-weight: 700;
        text-align: justify;
    }

span.MuiTypography-root.MuiTypography-caption.css-1k4kuxj-MuiTypography-root {
    font-weight: 700;
}

li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.css-1u5vxza-MuiListItem-root {
    background-color: #5D5FEF !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.css-1bbfk4o-MuiButtonBase-root-MuiButton-root {
    width: 100px;
}
.MuiBox-root.css-wfrraf {
    padding: 7px 16px !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.css-19bfiml-MuiButtonBase-root-MuiButton-root {
    min-width: 45px !important;
}

span.MuiTypography-root.MuiTypography-caption.css-1k4kuxj-MuiTypography-root {
    font-size: 14px !important;
}
.css-24bz98-MuiTypography-root{
    line-height: 0.5 !important;
}