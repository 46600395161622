.action-row {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .upload-btn, .clear-all-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    color: white;
  }
  
  .upload-btn {
    background-color: #6366f1;
  }
  
  .upload-btn:hover {
    background-color: #4f46e5;
  }
  
  .clear-all-btn {
    background-color: #dc2626;
  }
  
  .clear-all-btn:hover {
    background-color: #b91c1c;
  }