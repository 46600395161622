.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Nunito Sans;
  width: 250px; /* Set a fixed width to ensure consistency */
  background-color: #1e1e2f; 
  padding: 10px; /* Reduce padding for more space */
  color: #fff;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  overflow-y: auto; /* Allows scrolling if content overflows */
}

.logo-container {
  text-align: left;
  margin-bottom: 0px;
  padding: 10px 0; /* Reduce padding for better fit */
}

.logo {
  max-width: 50%; /* Adjusted for better fit */
  height: auto;
}

.menu-container {
  flex-grow: 1;
}

.sidebar nav ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.nav-button {
  width: 100%;
  text-align: left;
  padding: 10px 10px 10px 10px; /* Reduced padding for better fit */
  border: none;
  background: none;
  font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  align-items: left;
  font-size: 14px; /* Slightly smaller font size */
  color: #232323;
  transition: background-color 0.3s ease;
}

.nav-button span {
  margin-right: 8px; /* Adjusted spacing */
}

.nav-button.active {
  background-color: #5D5FEF;
  color: white;
  border-radius: 5px;
}

.nav-button:hover {
  background-color: #7d7fee;
  color: white;
}

.menu-divider {
  height: 2px;
  background-color: #808080;
  margin: 15px 0; /* Reduced spacing for better fit */
}

.logout-container {
  margin-top: auto;
}

.logout-button {
  width: 100%;
  padding: 10px 10px; /* Reduced padding for better fit */
  background-color: #e74d3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px; /* Slightly smaller font size */
}

.logout-button span {
  margin-right: 8px; /* Adjusted spacing */
}

.logout-button:hover {
  background-color: #c0392b;
}

.nav-button i {
  margin-right: 10px; /* Adds space between icon and text */
  font-size: 13px;  /* Adjust icon size if necessary */
}

.nav-button {
  display: flex;
  align-items: center; /* Aligns icon and text vertically */
}

.nav-button span {
  flex-grow: 1;
  text-align: left; /* Keeps text aligned to the left */
}


