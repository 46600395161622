.info-section {
    display: flex;
    background-color: #f0f0f0;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .info-icon {
    font-size: 25px;
    /* margin-right: 16px; */
  }
  
  .info-content {
    flex: 1;
    padding-left: 20px;
  }
  
  .info-content p {
    margin: 0 0 10px 0;
  }
  
  .info-content p:last-child {
    margin-bottom: 0;
  }
  
  .info-content strong {
    font-weight: bold;
  }