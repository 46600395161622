.upload-container {
    border: 2px solid #f0f2f2;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 20px;
    margin-top: 0;
  }
  
  .upload-area {
    border: 2px dashed #8b8b8b;
    background-color:#f9fafb ;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    cursor: pointer;
  }
  
  .upload-icon {
    font-size: 32px;
    margin-bottom: 10px;
  }
  
  .file-types {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .file-types span {
    background-color: #f3f4f6;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
  }