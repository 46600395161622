@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap");
.MuiBox-root.css-0 {
    background-color: #FFFFFF !important;
    text-align: -webkit-center !important;
}
h4.MuiTypography-root.MuiTypography-h4.MuiTypography-gutterBottom.css-yvxppd-MuiTypography-root {
    font-weight: 800 !important;
    font-size: 48px !important;
}
.css-yvxppd-MuiTypography-root {
    margin-bottom: 0 !important;
}
.cl-rootBox.cl-signIn-root.🔒️.cl-internal-phfxlr {
    width: 35rem !important;
}

h1.cl-headerTitle.🔒️.cl-internal-1tpvhd8{
    font-size: 30px;
}
h4, h6, p{
    text-align: center;
    font-family: 'Nunito', sans-serif !important;
}
span {
    font-family: 'Nunito', sans-serif !important;
    font-size: 16px !important;
}
p {
    font-size: 16px !important;
}
h4 {
    font-size: 48px !important;
}
.cl-cardBox.cl-signUp-start.lock:️.cl-internal-7hb1uf {
    width: 35rem !important;
}
.cl-cardBox.cl-signIn-start.🔒️.cl-internal-7hb1uf {
    width: 35rem !important;
}
.cl-cardBox.cl-signUp-start.🔒️.cl-internal-7hb1uf{
    width: 35rem !important;  
}
/* 
cl-card.cl-signIn-start:️.cl-internal-1p733cp {
    background-color: #F5F6FA;
}
.cl-internal-4x6jej > :first-of-type {
    padding: 1rem 2rem;
    background-color: #F5F6FA;
    width: 100%;
    place-content: center;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.css-iol86l {
   align-content: center;
}
button.cl-formButtonPrimary.cl-button.cl-internal-ttumny {
    background-color: #5D5FEF !important;
}
.cl-cardBox.cl-signIn-password.cl-internal-7hb1uf {
    width: 100%;
    height: 550px;
    background-color: #F5F6FA;
}
button.cl-formButtonPrimary.cl-button.cl-internal-ttumny {
    background-color: #5D5FEF;
}
.cl-card.cl-signIn-password.cl-internal-1p733cp {
    background-color: #F5F6FA;
}
.cl-cardBox.cl-signIn-alternativeMethods.cl-internal-7hb1uf {
    width: 100%;
    height: 550px;
    background-color: #F5F6FA;
    justify-content: center;
}

.cl-card.cl-signIn-alternativeMethods.cl-internal-1p733cp {
    background-color: #F5F6FA;
}

.cl-internal-4x6jej > :first-of-type {
   width: 100%;
    place-content: center;
    background-color: #F5F6FA;
}
.cl-cardBox.cl-signIn-havingTrouble.cl-internal-7hb1uf {
    width: 100%;
    height: 550px;
    background-color: #F5F6FA;
    justify-content: center;
}
.cl-card.cl-signIn-havingTrouble.cl-internal-1p733cp {
    background-color: #F5F6FA;
}
.cl-card.cl-signUp-start.cl-internal-1p733cp {
    background-color: #F5F6FA;
}
.cl-footerAction.cl-footerAction__signUp.cl-internal-1rpdi70 {
    width: 100%;
    place-content: center;
    background-color: #F5F6FA;
}
button.cl-formButtonPrimary.cl-button.cl-internal-ttumny {
   background-color: #5D5FEF;
}
h1.cl-headerTitle.cl-internal-1tpvhd8 {
    color: #5D5FEF;
}
.cl-card.cl-signIn-start.cl-internal-1p733cp {
    background-color: #F5F6FA;
} */