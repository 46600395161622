.campaigns-container {
  padding: 20px;
  margin-right: 3%;
  margin-left: 17%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaigns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.create-campaign-btn {
  background-color: #6c5ce7;
  color: white;
  font-size: 13px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaigns-table {
  width: 100%;
  border-collapse: collapse;
}

.campaigns-table th, .campaigns-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
}

.campaigns .status-badge.completed {
  background-color: #dafce8;
  color: #dafce8;
}

.status-badge.in-progress {
  background-color: #d5dbdb;
  color: #2c3e50;
}

.status-badge.pending {
  background-color: #f9e79f;
  color: #b7950b;
}

.actions-cell {
  display: flex;
  gap: 10px;
}

.action-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}

.view-btn { color: #5D5FEF; }
.edit-btn { color: #f39c12; }
.delete-btn { color: #e74c3c; }

.icon {
  font-size: 12px;
} 

.campaigns-container {
  font-family: Nunito Sans, sans-serif;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-left: 20%;
  padding: 20px;
}

.campaigns-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.campaigns-header h1 {
  font-size: 24px;
  margin: 0;
}

.create-campaign-btn {
  padding: 10px 20px;
  font-size: 12px;
  background-color: #5D5FEF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-campaign-btn:hover {
  background-color: #484ac5b3;
}

.campaigns-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.campaigns-table th, .campaigns-table td {
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.campaigns-table th {
  background-color: #f9f9f9;
  color: rgb(0, 0, 0);
  font-weight: bold;

}

.campaigns-table tbody tr:hover {
  background-color: #f2f2f2;
}

.action-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
}

.edit-btn {
  color: #5D5FEF;
}

.delete-btn {
  color: #5D5FEF;
}

.icon {
  font-size: 13px;
}
